import { stringify } from 'query-string'
import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_WHO_LIFE_EXP_DATA = 'GET_WHO_LIFE_EXP_DATA'
const GET_WHO_LIFE_EXP_DATA_SUCCESS = 'GET_WHO_LIFE_EXP_DATA_SUCCESS'
const GET_WHO_LIFE_EXP_DATA_FAILURE = 'GET_WHO_LIFE_EXP_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}
export const getWhoLifeExpAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_WHO_LIFE_EXP_DATA,
    url: `${process.env.apiUrl}v0/search/whoResponses`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getWhoLifeExpReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_WHO_LIFE_EXP_DATA: {
      return {
        data: {},
        loading: true,
        flag: true,
        error: false,
      }
    }
    case GET_WHO_LIFE_EXP_DATA_SUCCESS: {
      if (action.meta.type) {
        return {
          data: {
            ...state.data, [action.meta.type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_WHO_LIFE_EXP_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

// const GET_POPULTATION_TEN_DATA = 'GET_WHO_LIFE_EXP_DATA'
// const GET_WHO_LIFE_EXP_DATA_SUCCESS = 'GET_WHO_LIFE_EXP_DATA_SUCCESS'
// const GET_WHO_LIFE_EXP_DATA_FAILURE = 'GET_WHO_LIFE_EXP_DATA_FAILURE'

// const INITIAL_STATE = {
//   data: {},
//   loading: false,
//   error: false,
//   flag: false,
// }

// export const getWhoLifeExpAction = (payload, meta = {}) => {
//   return ({
//     type: GENERIC_ACTION,
//     payload,
//     meta: {
//       actionType: GET_WHO_LIFE_EXP_DATA,
//       url: `${process.env.apiUrl}v0/search/whoResponses`,
//       method: 'GET',
//       auth: true,
//       log: false, // for console
//       ...meta,
//     },
//     // showNotification: true, // show notification on success
//     // message: 'PI added successfully',
//     // hideNotification: false, // hide notification on error
//   })
// }

// export function getWhoLifeExpReducer(state = INITIAL_STATE, action) {
//   switch (action.type) {
//     case GET_WHO_LIFE_EXP_DATA: {
//       return {
//         data: {},
//         loading: true,
//         flag: true,
//         error: false,
//       }
//     }
//     case GET_WHO_LIFE_EXP_DATA_SUCCESS: {
//       // console.log('action.payload', action)

//       return {
//         data: action.payload.data,
//         loading: false,
//         flag: true,
//         error: false,
//       }
//     }
//     case GET_WHO_LIFE_EXP_DATA_FAILURE: {
//       return {
//         data: {},
//         loading: false,
//         flag: false,
//         error: true,
//       }
//     }
//     default:
//       return state
//   }
// }

const GET_COUNTRY_PROFILE_FILTERS = 'GET_COUNTRY_PROFILE_FILTERS'
const GET_COUNTRY_PROFILE_FILTERS_SUCCESS = 'GET_COUNTRY_PROFILE_FILTERS_SUCCESS'
const GET_COUNTRY_PROFILE_FILTERS_FAILURE = 'GET_COUNTRY_PROFILE_FILTERS_FAILURE'

export const getCountryProfileFiltersAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COUNTRY_PROFILE_FILTERS,
      url: `${process.env.apiUrl}v0/country_profile/getCountryProfileFilters`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getCountryProfileFiltersReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTRY_PROFILE_FILTERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTRY_PROFILE_FILTERS_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COUNTRY_PROFILE_FILTERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_COUNTRY_PROFILE_DATA = 'GET_COUNTRY_PROFILE_DATA'
const GET_COUNTRY_PROFILE_DATA_SUCCESS = 'GET_COUNTRY_PROFILE_DATA_SUCCESS'
const GET_COUNTRY_PROFILE_DATA_FAILURE = 'GET_COUNTRY_PROFILE_DATA_FAILURE'

export const getCountryProfileDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COUNTRY_PROFILE_DATA,
      url: `${process.env.apiUrl}v0/country_profile/getCountryProfile`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getCountryProfileDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTRY_PROFILE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTRY_PROFILE_DATA_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COUNTRY_PROFILE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const UPDATE_COUNTRY_PROFILE_DATA = 'UPDATE_COUNTRY_PROFILE_DATA'
const UPDATE_COUNTRY_PROFILE_DATA_SUCCESS = 'UPDATE_COUNTRY_PROFILE_DATA_SUCCESS'
const UPDATE_COUNTRY_PROFILE_DATA_FAILURE = 'UPDATE_COUNTRY_PROFILE_DATA_FAILURE'

export const updateCountryProfileDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: UPDATE_COUNTRY_PROFILE_DATA,
      url: `${process.env.apiUrl}/v0/country_profile/addCountryProfile`,
      method: 'POST',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'Saved successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function updateCountryProfileDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case UPDATE_COUNTRY_PROFILE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case UPDATE_COUNTRY_PROFILE_DATA_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case UPDATE_COUNTRY_PROFILE_DATA_FAILURE: {
      return {
        data: action.payload,
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
const DELETE_COUNTRY_PROFILE_DATA = 'DELETE_COUNTRY_PROFILE_DATA'
const DELETE_COUNTRY_PROFILE_DATA_SUCCESS = 'DELETE_COUNTRY_PROFILE_DATA_SUCCESS'
const DELETE_COUNTRY_PROFILE_DATA_FAILURE = 'DELETE_COUNTRY_PROFILE_DATA_FAILURE'

export const deleteCountryProfileDataAction = (payload, meta = {}) => {
  console.log('payload ===>', payload)
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: DELETE_COUNTRY_PROFILE_DATA,
      url: `${process.env.apiUrl}/v0/country_profile/deleteCountryProfile?${stringify(payload.params)}`,
      method: 'DELETE',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function deleteCountryProfileDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case DELETE_COUNTRY_PROFILE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case DELETE_COUNTRY_PROFILE_DATA_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case DELETE_COUNTRY_PROFILE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
