import { combineEpics } from 'redux-observable';
import commonEpics from '../common/epics';

import { genericEpic, apiCallEpic } from './container/SearchResults/logic';
import { getAutocompleteValuesEpic } from './container/SearchBar/logic';
import { getDeepdiveValuesEpic } from './container/DeepDiveScreen/logic';
import { getDocProfileValuesEpic } from './container/DoctorProfile/logic';
import {
  addProjectDetailEpic, updateProjectDetailEpic, getautosuggestvalueEpic, getotherautosuggestvalueEpic,
  getRegionValueEpic,
} from './container/AddProjectName/logic';
import { getFilterValuesEpic } from './container/AddFilters/logic';
import { createBookmarkEpic } from '../common/components/Bookmark/logic';
import { addNetworkEpic, addPiToNetworkEpic } from './container/Network/logic';
import { deleteNetworkEpic } from './container/NetworkDetails/logic';
import { getPaymentDataEpic, getPaymentMoreDataEpic } from './container/DashboardPayments/logic';
import { getCensusDataEpic } from './container/DashboardData/logic';
import { uploadSponsorsFileEpic } from './container/UploadSponsorsList/logic';
import { getCapacityGraphDataEpic } from './container/Capacity/logic';
import { uploadBulkUpdateFileEpic } from './components/BulkUpdate/logic';

export default combineEpics(
  ...Object.values(commonEpics),
  genericEpic,
  apiCallEpic,
  getAutocompleteValuesEpic,
  getotherautosuggestvalueEpic,
  getDeepdiveValuesEpic,
  getDocProfileValuesEpic,
  addProjectDetailEpic,
  updateProjectDetailEpic,
  getFilterValuesEpic,
  getautosuggestvalueEpic,
  createBookmarkEpic,
  addNetworkEpic,
  addPiToNetworkEpic,
  deleteNetworkEpic,
  getPaymentDataEpic,
  getCensusDataEpic,
  uploadSponsorsFileEpic,
  getCapacityGraphDataEpic,
  getPaymentMoreDataEpic,
  getRegionValueEpic,
  uploadBulkUpdateFileEpic,

);
